#about_me {
    width: 80%;
    display: grid;
    grid-template-areas: 
    "a b"
    "a c"
    "d .";
    grid-template-columns: 20%;
    grid-template-rows: auto;
}

p{
    text-indent: 20px;
    text-align: justify;
    font-size: 1rem;
}

#portrait{
    width: 80%;
    display: grid;
    place-items: center;
    justify-self: center;
    border-radius: 10px;
    overflow: hidden;
}

img {
    width: 100%;
    border-radius: 7px;
    overflow: hidden;
}

#portrait img{
    width: 90%;
    border: 5px solid white;
}

#presentation {
    color: white;
    font-size: 1vw;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    overflow: hidden;
}
#presentation p {
    width: 90%;
    margin: auto;
}

#reseaux {
    display: flex;
    flex-direction: row;
    align-items: center;
}
#reseaux img {
    width: 50%;   
}
#reseaux a {
    display: flex;
    justify-content: center;
}

#languages {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

#languages img {
    width: 10%;
}

@media all and (max-width: 1024px) {
    #about_me{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #about_me > * {
        padding: 5%;
    }
}