#contact{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;

}
    #form1{
        width: 40%;
        display: flex;
        flex-direction: column;
    }
    #form2{
        width: 40%;
    }

    input {
        color: white;
        margin: 1vw;
        padding: 1vw;
        font-size: 1vw;
        background-color: rgba(10, 9, 9, 0.134);
        box-shadow: 2px 2px 2px grey;
        border: none;
        border-radius: 10px;
    }

    #message {
        width: 90%;
        height: 20rem;
        margin: 1vw;
        border: none;
        border-radius: 10px;
        background-color: rgba(10, 9, 9, 0.134);
        box-shadow: 2px 2px 2px grey;
    }
    textarea {
        padding: 1vw;
        font-size: 1vw;
        color: white;
    }
    button {
        width: 90%;
        cursor: pointer;
        border: none;
        background: none;
        color: white;
        font-size: 1vw;
    }
    

@media all and (max-width: 1024px) {

    h2 {
        padding: 5%;
    }
    #contactForm > * {
        padding: 5%;
    }
    p {
        font-size: 3vw;
    }

    form {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    #form1, #form2 {
        width: 90%;
        margin: auto;       
    }

    label, input, button, #message {
        font-size: 4vw;
    }

    
}

