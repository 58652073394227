@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

body {
  margin: 0;
  font-family: 'Bebas Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.1rem;
}

p ,li {
  font-family: 'Courier New', Courier, monospace !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  min-height: 100vh;
  background: radial-gradient(circle, #031e36 0, #000 100%),
  url('../public/assets/images/logoCustom.png');
    background-position: center;
    background-size: contain;
    background-blend-mode: difference;
    background-repeat: no-repeat;
}
.glass{
  background: rgba( 255, 255, 255, 10% );
  box-shadow: 0 0 5rem 0 rgba( 31, 38, 135, 37%);
  -webkit-backdrop-filter: blur( 1vw );
  backdrop-filter: blur( 1vw );
  border-radius: 1vw;
}
#Home {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: center;
}
#Home > * {
  height: 70vh;
}

#Home img {
  object-fit: contain;
}
#menu {
  width: 25%;
  height: 10%;
  text-align: center;
}

.close {
  animation-name: close;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
}

.open {
  animation-name: open;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
  margin: 5vh;
  padding: 2vh;

} 

@keyframes open{
  from {
    width: 0%;

  }
  to {
    width: 80%;
  }
}

@keyframes close {
  from {
    width: 80%;

  }
  to {
    width: 0%;

  }
}


@media all and (max-width: 1024px) {
  .open{
    margin: 30%;
  }
  #Home > * {
    height: auto;
  }
  #menu {
    z-index: 1;
    background-color: #000;
  } 
}