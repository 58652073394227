#cv {
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

#cv > * {
    width: 90%;
}

h2 {
    font-size: 3vh;
    color: rgb(255, 199, 44);
    margin: 0 0 2vh 0;
}
h3 {
    font-size: 3vh;
    margin: 1.5vh 0 1.5vh 0;
}
p {
    margin: 0 0 0 2vh;
    letter-spacing: normal;
}

#openclassrooms, #bts, #freelance, #dev_openclassrooms, #technicien {
    line-height: 1.5em;
}

#formation > div, #experience > div {
    margin-left: 1vw;
}

#formation, #experience {
    width: 30%;
    padding: 3%;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    box-shadow: 10px 10px 20px black;

}

@media all and (max-width: 1024px) {

    #cv {
        flex-direction: column;
        padding: 5%;
    }
    #cv > * {
        width: 80%;
        margin: 5%;
        padding: 5%;
    }

}