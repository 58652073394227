#slider{
    width: 90%;
    height: 90%;
    margin: auto;
}


.slideshow{ 
    position: relative;
    height: 15vw;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 20px;
}

.slideshow p {
    width: 100%;
    color: white;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    position: absolute;
    bottom: -2rem;
    margin: 0;
}

@keyframes right {
    0% {
        transform: scaleX(0);
    }
    100% {
        transform: scaleX(1);
    }
}
@keyframes left {
    0% {
        transform: scaleX(0);
    }
    100% {
        transform: scaleX(-1);
    }
}
.none {
    display: none;
}
.btn-right, 
.btn-left{
    width: 3%;
    cursor: pointer;
}

.btn-left{
    left: 30px;

}

.btn-right{
    right: 30px;

}

.show{
    display: flex;
    gap: 1vw;
}
.show img {
    flex: 1;
    width: 20%;
    box-shadow: 0 2px 8px black;
    cursor: pointer;
}

.active{
    border: 5px solid #ffffff;
}

.logo_techno {
    width: 3vw;
    filter: drop-shadow( 0 0 30px white);
}

#slider {
    grid-area: a;
}

#languages_projet {
    height: 75%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#lien_projet {
    display: flex;
    justify-content: space-around;
    text-align: center;
}