#menu {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    top: 85vh;
    height: auto;
}

#menu i {
    font-size: 2.5vw;
    color: grey;
}

.btn_menu {
    background: none;
    border: none;
    cursor: pointer;
    padding: 3%;
}
.btn_menu:hover i {
    color: white!important;
}

@media all and (max-width: 1024px) {

    #menu {
        position: fixed;
        top: 0;
        width: 100%;
    }
}
